<template>
  <div>
    <v-card v-if="!isSuccess" class="text-center pa-1" elevation="2">
      <v-card-title class="justify-center display-1 mb-2">{{ $t('auth.reset.title') }}</v-card-title>
      <v-card-subtitle>{{ $t('auth.reset.subtitle') }}</v-card-subtitle>
      <v-card-text>
        <v-text-field
          v-model="newPassword"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :error="errorNewPassword"
          :error-messages="errorNewPasswordMessage"
          :label="$t('auth.reset.new-password')"
          :rules="[rules.required]"
          :type="showPassword ? 'text' : 'password'"
          class="mt-4"
          name="password"
          outlined
          @change="resetErrors"
          @click:append="showPassword = !showPassword"
        />

        <v-btn
          :loading="isLoading"
          block
          color="primary"
          depressed
          x-large
          @click="confirmPasswordReset"
        >{{ $t('auth.reset.button') }}
        </v-btn>
      </v-card-text>
    </v-card>
    <v-card v-else class="pa-1" elevation="2">
      <v-card-title class="justify-center">
        <span class="text-subtitle-1">{{ $t('auth.reset.success') }}</span>
      </v-card-title>
    </v-card>

    <div class="text-center mt-6">
      <router-link to="/auth/signin">
        {{ $t('auth.reset.back-to-signin') }}
      </router-link>
    </div>
  </div>
</template>


<script>
/*
|---------------------------------------------------------------------
| Reset Page Component
|---------------------------------------------------------------------
|
| Page Form to insert new password and proceed to sign in
|
*/
export default {
  data() {
    return {
      isLoading: false,
      isSuccess: false,

      // form
      showNewPassword: true,
      newPassword: '',

      // form error
      errorNewPassword: false,
      errorNewPasswordMessage: '',

      // show password field
      showPassword: false,

      // input rules
      rules: {
        required: (value) => (value && Boolean(value)) || 'Required'
      }
    }
  },
  computed: {
    token() {
      return this.$route.params.token;
    }
  },
  methods: {
    confirmPasswordReset() {
      this.isLoading = true
      this.$api.go('/api/reset_password/change').post({
        token: this.token,
        plainPassword: this.newPassword
      }).then(() => {
        this.isLoading = false;
        this.isSuccess = true;
      }).catch(e => {
        this.isLoading = false;
        this.errorNewPassword = true;
        this.errorNewPasswordMessage = e.description;
      })
    },
    resetErrors() {
      this.errorNewPassword = false
      this.errorNewPasswordMessage = ''
    }
  }
}
</script>

